import { navbar } from './navbar.js';
import { categories } from './categories.js';
import { checkout } from './checkout.js';
import { cart } from './cart.js';
import { team } from './team.js';
import { home } from './home.js';
import { footer } from './footer.js';
import { product } from './product.js';
import { account } from './account.js';
import { notFound } from './notFound.js';
import { countries } from './countries.js';


export const lang = {
  modules: {
    navbar: navbar,
    categories: categories,
    checkout: checkout,
    cart: cart,
    footer: footer,
    product:product,
    team: team,
    home: home,
    notFound: notFound,
    countries: countries,
    account: account
  },
  state: {
    currentLang:'DE',
    fallbackLang:'EN'
  },
  mutations: {
    
      setLang(state, lang){
        if(lang == 'DE'){
          state.currentLang = lang;
          state.fallbackLang = 'EN';
          window.localStorage.setItem('rewhiteLang', 'DE');
        } else {
          state.currentLang = lang;
          state.fallbackLang = 'DE';
          window.localStorage.setItem('rewhiteLang', 'EN');
        }
      },
      
  },
  actions: {
  }
};
