<template>
  <recheckItemsQuery />
</template>

<script>
import recheckItemsQuery from '../graphql/saleor/recheckItemsQuery.vue';


export default {
  name: 'cartLogic',
  components: { recheckItemsQuery },
  
  computed: {
    //graphql
    recheckItems(){
      return this.$store.state.data.recheckItems;
    },
    //data
    cart(){
      return this.$store.state.data.cart;
    },
    channel(){
      return this.$store.state.data.channel;
    }
  },
  
  mounted(){
  
    //Cart detection
    if (typeof(Storage) !== "undefined") {
      if (window.localStorage.rewhiteCart) {
        console.log("cart exists");
        this.$store.commit('loadCart', window.localStorage.rewhiteCart);
        //var ids = ["UHJvZHVjdFZhcmlhbnQ6MQ==", "UHJvZHVjdFZhcmlhbnQ6Nw==", "UHJvZHVjdFZhcmlhbnQ6Mg==", "UHJvZHVjiFZhcmlhbnQ6Mg=="];
        var ids = [];
        
        this.cart.items.forEach(function(item){
          ids.push(item.id);
        });
        
        this.recheckItems({
        channel: this.channel,
        ids: ids
        })
        .then(result => {
          if (result.error) {
            console.log(result.error);
            this.$store.commit('resetCart');
          } else {
            var that = this;

            result.data.productVariants.edges.forEach(function(item){
              var itemIndex = that.cart.items.findIndex(x => x.id === item.node.id);
              if(itemIndex !== -1){
                if(that.cart.items[itemIndex].price !== item.node.pricing.price.gross.amount || JSON.stringify(that.cart.items[itemIndex].meta) !== JSON.stringify(item.node.metadata) ){
                  that.$store.commit('updateCartItem', {id: item.node.id, price: item.node.pricing.price.gross.amount, meta: item.node.metadata });
                }
              }
            });
        
          }
        });
        
      } else {
        window.localStorage.setItem('rewhiteCart', '');
        console.log("creating new cart");
      }
    } else {
      console.log("No webstorage support");
    }
  }
  
};
</script>