export const cart = {
  state: {
    DE:{
      cart:'Warenkorb',
      total1: 'In deinem Warenkorb befinden sich',
      total2: 'Artikel für insgesamt',
      trees1sing: 'Mit dieser Bestellung wird',
      trees2sing: 'Baum in Indonesien gepflanzt',
      trees1plur: 'Mit dieser Bestellung werden',
      trees2plur: 'Bäume in Indonesien gepflanzt',
      cartEmpty:'Du hast noch keine Artikel im Warenkorb',
      toCheckout:'Zum Checkout',
      infob2b:'*Alle Preise zzgl. 19% MwSt., Versandkosten werden im Checkout hinzugefügt.',
      infob2c:'*Alle Preise inkl. 19% MwSt., Versandkosten und Gutscheine werden im Checkout hinzugefügt.'
    },
    EN:{
      cart:'Shopping cart',
      total1: 'You currently have',
      total2: 'products in your cart for a total amount of',
      trees1sing: 'With this order',
      trees2sing: 'tree will be planted in Indonesia.',
      trees1plur: 'With this order',
      trees2plur: 'trees will be planted in Indonesia.',
      cartEmpty:'You dont have any product in your cart yet.',
      toCheckout:'Checkout',
      infob2b:'*All prices excl. 19% VAT, shipping costs will be added during checkout.',
      infob2c:'**All prices incl. 19% VAT, shipping costs and coupon codes will be added during checkout.'
    }
  }
};
