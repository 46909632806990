export const product = {
  state: {
    DE:{
      notify1:'Wir schicken dir eine Email, sobald der Artikel wieder verfügbar ist.',
      notify2:'Kein Spam, versprochen!',
      name:'Dein Name',
      email:'Email Adresse',
      notify3:'Danke! Wir haben deine Daten erhalten und schicken dir eine Email, wenn der Artikel wieder verfügbar ist.',
      ek:'EK ',
      uvp:'UVP',
      available:'Sofort lieferbar',
      lowStock:'Nur noch wenige verfügbar',
      soldOut:'Aktuell ausverkauft',
      loading:'Lade Preise und Verfügbarkeiten..',
      addToCart:'In den Warenkorb',
      productAdded:'Produkt hinzugefügt',
      loading2:'Lade Größen..',
      notify:'Benachrichtige mich',
      back:'Zurück zum Produkt',
      related:'ÄHNLICHES PRODUKT',
      backTo:'Zurück zu'
    },
    EN:{
      notify1:'We will send you an email once the item is back in stock',
      notify2:'No spam, promise!',
      name:'Your name',
      email:'Email address',
      notify3:'Thank you. We received your data and will notify you once the item is back in stock.',
      ek:'PP ',
      uvp:'RRP',
      available:'Available',
      lowStock:'Only a few left',
      soldOut:'Out of stock',
      loading:'Loading prices and availability..',
      addToCart:'Add to cart',
      productAdded:'Product added',
      loading2:'Loading sizes..',
      notify:'Notify me',
      back:'Back to product',
      related:'RELATED',
      backTo:'Back to'
    }
  }
};
