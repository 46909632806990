<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed } from 'vue';


export default {
  name:"logoutMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const logoutMutation = gql`
      mutation {
        tokensDeactivateAll {
          accountErrors {
            field
            message
            code
          }
        }
      }
      `;
  
    const { execute: logoutUser } = useMutation(logoutMutation,{
      context: computed(() => store.state.data.headers)
    });
      
    store.commit('graphqlData', { item: 'logoutUser', result: logoutUser });
  },
}
</script>