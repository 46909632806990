import { createApp } from 'vue'
import { createClient } from 'villus';
import App from './App.vue'
import router from './router'
import store from './store'
import './css/tailwind.css'
import { initializeApp } from "firebase/app";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://5f155f134e1545ccaf6b2dc91b336059@o557485.ingest.sentry.io/6034121",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});



const firebaseConfig = {
  apiKey: "AIzaSyDn1r-vkCcD2iF-iAdrYqhJuB1nHS0II30",
  authDomain: "rewhite-86006.firebaseapp.com",
  projectId: "rewhite-86006",
};

const app = initializeApp(firebaseConfig);


createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
