// eslint-disable-next-line import/prefer-default-export
export const account = {
  state: {
    DE: {
      login: 'Login',
      underDevelopment: 'Der Account Bereich wird gerade überarbeitet. Du kannst auch als Geschäftskunde als Gast bestellen, bei einer späteren Registrierung werden deine alten Bestellungen deinem Account zugeordnet.',
      register: 'Registrieren',
      resetPass: 'Passwort zurücksetzen',
      loginInfo: 'Logge dich in deinen Geschäftskundenaccount ein.',
      registerInfo: 'Registriere dich um deine Bestellungen zu verwalten.',
      passReset: 'Setze dein Passwort zurück',
      email: 'Email Adresse',
      pass: 'Passwort',
      login2: 'Einloggen',
      new: 'Neu hier? Registriere dich hier.',
      forgotPass: 'Passwort vergessen?',
      back: 'Zurück zum Login.',
      cc: 'CC für Bestellungen',
      name: 'Vorname',
      lastName: 'Nachname',
      save: 'Änderung speichern',
      newEmail: 'Neue Email Adresse',
      passAgain: 'Passwort erneut eingeben',
      changeEmail: 'Email Adresse ändern',
      oldPass: 'Altes Passwort',
      newPass: 'Neues Passwort',
      changePass: 'Passwort ändern',
      changeDetails: 'Kontaktdaten bearbeiten',
      deleteAccount: 'Konto löschen',
      logout: 'Ausloggen',
      shipping: 'Lieferadresse',
      billing: 'Rechnungsadresse',
      company: 'Unternehmen',
      street: 'Straße und Hausnummer',
      street2: 'Zusätliche Adresse',
      zip: 'Postleitzahl',
      city: 'Stadt',
      region: 'Region',
      changeShipping: 'Lieferadresse ändern',
      back2: 'Zurück',
      changeBilling: 'Rechnungsadresse ändern',
      editShipping: 'Lieferadresse bearbeiten',
      editBilling: 'Rechnungsadresse bearbeiten',
      lastOrder: 'Deine letzten Bestellungen',
      products: 'Produkte',
      downloads: 'Downloads',
      downloadPricelist: 'Aktuelle Preisliste herunterladen',
      downloadCatalogue: 'Produktkatalog herunterladen',
      downloadImg: 'Logopaket herunterladen',
      confirmEmail: 'Wir haben dir eine Email geschickt, damit du die Löschung deines Accounts bestätigen kannst.',
      confirmEmail2: 'Wir haben dir eine Email geschickt, mit der du deine neue Email Adresse bestätigen kannst.',
      confirmEmail3: 'Wir haben dir eine Email geschickt, damit du deinen Account bestätigen kannst.',
      confirmEmail4: 'Wir haben dir eine Email geschickt, mit der du dein Passwort zurücksetzen kannst.',
    },
    EN: {
      login: 'Login',
      underDevelopment: 'Our new shop has just launched and the account area is still under developement. Come back later to create a new account.',
      register: 'Register',
      resetPass: 'Reset password',
      loginInfo: 'Login to your business customer account',
      registerInfo: 'Register a new account.',
      passReset: 'Reset your password',
      email: 'Email Address',
      pass: 'Password',
      login2: 'Login',
      new: 'No Account? Register here.',
      forgotPass: 'Forgot password?',
      back: 'Back to login.',
      cc: 'CC for order emails',
      name: 'Name',
      lastName: 'Last Name',
      save: 'Save changes',
      newEmail: 'New email address',
      passAgain: 'Enter password again',
      changeEmail: 'Change email address',
      oldPass: 'Old password',
      newPass: 'New password',
      changePass: 'Change password',
      changeDetails: 'Edit contact details',
      deleteAccount: 'Delete Account',
      logout: 'Logout',
      shipping: 'Shipping address',
      billing: 'Billing address',
      company: 'Company',
      street: 'Address',
      street2: 'Additional address',
      zip: 'Postal code',
      city: 'City',
      region: 'Region',
      changeShipping: 'Change shipping address',
      back2: 'Back',
      changeBilling: 'Change billing address',
      editShipping: 'Edit shipping address',
      editBilling: 'Edit billing address',
      lastOrder: 'Your last order',
      products: 'Products',
      downloads: 'Downloads',
      downloadPricelist: 'Download recent pricelist',
      downloadCatalogue: 'Download recent prodcut catalogue',
      downloadImg: 'Download logo images',
      confirmEmail: 'We have sent an email to your mailbox to verify the deletion of your account.',
      confirmEmail2: 'We have sent an email to your mailbox to verify your new email address.',
      confirmEmail3: 'We have sent an email to your mailbox to verify your account.',
      confirmEmail4: 'We have sent an email to your mailbox to reset your password.',

    },
  },
};
