import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'

const Home = () => import('../views/Home.vue')
const Content = () => import('../views/Content.vue')
const Products = () => import('../views/Products.vue')
const Categories = () => import('../views/Categories.vue')
const Cart = () => import('../views/Cart.vue')
const Checkout = () => import('../views/Checkout.vue')
const Account = () => import('../views/Account.vue')
const Confirm = () => import('../views/Confirm.vue')
const AGB = () => import('../views/AGB.vue')
const Impressum = () => import('../views/Impressum.vue')
const Team = () => import('../views/Team.vue')
const TeamMember = () => import('../views/TeamMember.vue')
const Widerrufsbelehrung = () => import('../views/Widerrufsbelehrung.vue')
const Datenschutz = () => import('../views/Datenschutz.vue')
const NotFound = () => import('../views/NotFound.vue')


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shop/:slug',
    name: 'Categories',
    component: Categories,
    props: true
  },
  {
    path: '/:slug',
    name: 'Content',
    component: Content,
    props: true
  },
  {
    path: '/kaufen/:slug',
    name: 'Products',
    component: Products,
    props: true
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    props: true
  },
  {
    path: '/confirm/:slug',
    name: 'Confirm',
    component: Confirm,
    props: true
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    props: false
  },
  {
    path: '/team/:slug',
    name: 'TeamMember',
    component: TeamMember,
    props: true
  },
  {
    path: '/agb',
    name: 'AGB',
    component: AGB,
    props: true
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum,
    props: true
  },
  {
    path: '/widerrufsbelehrung',
    name: 'Widerrufsbelehrung',
    component: Widerrufsbelehrung,
    props: true
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz,
    props: true
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    props: true
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    props: false
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  store.commit('setNavigatingMobile', false);
})

export default router
