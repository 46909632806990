/* eslint-disable no-plusplus */
import * as md5 from 'md5';

// eslint-disable-next-line import/prefer-default-export
export const data = {
  state: {
    // GRAPHQL
    categoryItems: null,
    categoryImages: null,
    allNews: null,
    items: null,
    registerAccount: null,
    loginUser: null,
    logoutUser: null,
    userDetails: null,
    getUserDetails: null,
    updateAccount: null,
    updateAccountMeta: null,
    changeUserEmail: null,
    changeUserPass: null,
    resetPass: null,
    deleteAccount: null,
    createAddress: null,
    updateAddress: null,
    createCheckout: null,
    createCheckoutisFetching: null,
    recheckQuantities: null,
    recheckQuantitiesIsFetching: null,
    addCoupon: null,
    addCouponIsFetching: null,
    removeCoupon: null,
    removeCouponIsFetching: null,
    updateShipping: null,
    updateShippingIsFetching: null,
    updateBilling: null,
    updateBillingIsFetching: null,
    setShippingMethod: null,
    setShippingMethodIsFetching: null,
    createPayment: null,
    createPaymentIsFetching: null,
    completeCheckout: null,
    completeCheckoutIsFetching: null,
    updataMetadata: null,
    validation: null,
    confirmAccount: null,
    confirmEmailChange: null,
    setNewPass: null,
    confirmDeleteAccount: null,
    contentImages: null,
    content: null,
    homeImages: null,
    home: null,
    teamMembers: null,
    teamMemberImages: null,
    result: null,
    productImages: null,
    result2: null,
    isFetching: null,
    singleTeamMember: null,
    singleTeamMemberImages: null,
    allTeamMembers: null,
    allTeamMemberImages: null,
    recheckItems: null,
    categories: null,
    currentCategory: null,
    refreshToken: null,
    seo: null,

    // VARS
    vars: {
      b2clowOnStock: 10,
      b2blowOnStock: 100,
      outOfStock: 0,
    },

    // ENUMS
    countries: [
      { name: 'Deutschland', value: 'DE', taxes: true },
      { name: 'Österreich', value: 'AT', taxes: true },
      { name: 'Dänemark', value: 'DK', taxes: true },
      { name: 'Frankreich', value: 'FR', taxes: true },
      { name: 'Schweden', value: 'SE', taxes: true },
      { name: 'Niederlande', value: 'NL', taxes: true },
      { name: 'Luxemburg', value: 'LU', taxes: true },
      { name: 'Belgien', value: 'BE', taxes: true },
      { name: 'Italien', value: 'IT', taxes: true },
      { name: 'Singapur', value: 'SG', taxes: false },
      { name: 'Spanien', value: 'ES', taxes: true },
      { name: 'Tschechien', value: 'CZ', taxes: true },
      { name: 'Polen', value: 'PL', taxes: true },
      { name: 'Irland', value: 'IE', taxes: true },
      { name: 'Schweiz', value: 'CH', taxes: false },
      { name: 'England', value: 'GB', taxes: false },
      { name: 'Estland', value: 'EE', taxes: true },
      { name: 'Finnland', value: 'FI', taxes: true },
      { name: 'Griechenland', value: 'GR', taxes: true },
      { name: 'Island', value: 'IS', taxes: true },
      { name: 'Japan', value: 'JP', taxes: false },
      { name: 'Litauen', value: 'LT', taxes: true },
      { name: 'Lettland', value: 'LV', taxes: true },
      { name: 'Norwegen', value: 'NO', taxes: false },
      { name: 'Portugal', value: 'PT', taxes: true },
      { name: 'Russland', value: 'RU', taxes: false },
      { name: 'Korea', value: 'KR', taxes: false },
      { name: 'Thailand', value: 'TH', taxes: false },
      { name: 'USA', value: 'US', taxes: false },
    ],

    filter: [
      { textId: 'routesetter', filterVal: 'Route' },
      { textId: 'athletes', filterVal: 'Ath' },
      { textId: 'photographers', filterVal: 'otog' },
    ],

    accountTabs: [
      'Account',
      'Kontaktdaten',
      'Bestellungen',
      'Downloads',
    ],

    // CART
    cart: {
      md5: '',
      expire: null,
      items: [],
    },

    // ACCOUNT

    currentTab: 'Account',

    // AUTH
    accessToken: null,
    headers: null,
    auth: false,
    logout: {},

    // CHECKOUT
    checkoutCompleted: false,
    showCartInCheckout: false,
    checkout: {},
    checkoutAmount: null,
    checkoutTaxes: true,
    checkoutAmountWithTaxes: null,
    discountCode: null,
    discountAmount: null,
    selectedCountryCode: 'DE',
    checkoutToken: null,
    availableShippingMethods: {},
    shippingCost: null,

    // NAV
    hideNav: false,
    navBarBackground: true,

    // TEAM
    athleteSlug: '',
    currentFilter: [],

    countryCode: 'DE',

    addingItem: false,
    notify: false,
    submitted: false,
    navigatingMobile: false,

    channel: 'b2c',
    paginationAfter: '',
    position: '',
    filteredSelectedSizeIndex: 0,
    selectedSizeIndex: 0,
    selectedColor: null,
    categorySlug: '',
    contentSlug: '',
    productSlug: '',
    colorCodes: {
      White: '#FFFFFF',
      Grey: '#D7D5D4',
      Anthracite: '#5F5F5F',
    },
  },
  mutations: {

    // ----------------------------------------------//
    // ------------------GRAPHQL---------------------//
    // ----------------------------------------------//

    graphqlData(state, payload) {
      state[payload.item] = payload.result;
    },

    // ----------------------------------------------//
    // --------------------AUTH----------------------//
    // ----------------------------------------------//

    setCSRF(state, CSRF) {
      console.log('----- Setting CSRF -----');
      window.localStorage.setItem('rewhiteSession', CSRF);
    },

    setAccessToken(state, token) {
      console.log('----- Setting Access Token -----');
      state.accessToken = token;
      state.headers = {
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
    },

    logoutUser(state, logout) {
      console.log('----- Logging out User -----');
      console.log(logout);
      state.logout = logout;
    },

    setUserAuthStatus(state, status) {
      console.log('----- Setting User Auth Status -----');
      state.auth = status;
      if (!status) {
        console.log('----- Removing CSRF -----');
        window.localStorage.removeItem('rewhiteSession');
      }
    },

    // ----------------------------------------------//
    // ------------------ACCOUNT---------------------//
    // ----------------------------------------------//

    setCurrentTab(state, tab) {
      state.currentTab = tab;
    },

    setUserDetails(state, userDetails) {
      console.log('----- Setting User Details -----');
      console.log(userDetails);
      state.userDetails = userDetails;
    },

    // ----------------------------------------------//
    // ------------------CHECKOUT--------------------//
    // ----------------------------------------------//

    updateCheckout(state, checkout) {
      console.log('CHECKOUT UPDATED');
      console.log(checkout);
      state.checkout = checkout;
    },

    changeCountryCode(state, countryCode) {
      console.log(countryCode);
      state.selectedCountryCode = countryCode;
    },

    setCheckoutToken(state, token) {
      console.log('setting token');
      state.checkoutToken = token;
    },

    setCheckoutAmount(state, amount) {
      state.checkoutAmount = amount;
    },

    setCheckoutTotalIncludesTaxes(state, value) {
      state.checkoutTotalIncludesTaxes = value;
    },

    setCheckoutAmountWithTaxes(state, amount) {
      state.checkoutAmountWithTaxes = amount;
    },

    setAvailableShippingMethods(state, availableShippingMethods) {
      const filteredShippingMethods = [];
      availableShippingMethods.forEach((method) => {
        if (method.name !== 'Abholung') {
          filteredShippingMethods.push(method);
        }
      });

      state.availableShippingMethods = filteredShippingMethods;
    },

    setShippingCost(state, cost) {
      state.shippingCost = cost;
    },

    setDiscount(state, payload) {
      state.discountCode = payload.code;
      state.discountAmount = payload.amount;
    },

    resetCheckout(state) {
      console.log('resetting checkout');
      state.checkout = {};
      state.checkoutToken = null;
      state.checkoutAmount = null;
      state.availableShippingMethods = {};
      state.availablePaymentGateways = {};
      state.discountCode = null;
      state.discountAmount = null;
    },

    setCheckoutCompleted(state, value) {
      state.checkoutCompleted = value;
    },

    showCartInCheckout(state, value) {
      state.showCartInCheckout = value;
    },

    // ----------------------------------------------//
    // --------------------CART----------------------//
    // ----------------------------------------------//

    saveCart(state) {
      const now = new Date(Date.now());
      const expire = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, now.getHours(), now.getMinutes());
      state.cart.expire = expire;
      state.cart.md5 = md5(state.cart.items);
      const parsed = JSON.stringify(state.cart);
      window.localStorage.setItem('rewhiteCart', parsed);
    },

    loadCart(state, cart) {
      const parsed = JSON.parse(cart);
      const now = new Date(Date.now());
      const expire = new Date(parsed.expire);
      if (now > expire) {
        console.log('cart expired');
        this.commit('resetCart');
      } else {
        state.cart = parsed;
      }
    },

    updateCartItem(state, payload) {
      console.log('updating item..');
      const itemIndex = state.cart.items.findIndex((x) => x.id === payload.id);
      if (itemIndex !== -1) {
        state.cart.items[itemIndex].price = payload.price;
        state.cart.items[itemIndex].meta = payload.meta;
        this.commit('saveCart');
      }
    },

    resetCart(state) {
      // deleting cart
      window.localStorage.removeItem('rewhiteCart');
      state.cart.md5 = '';
      state.cart.items = [];
      // creating empty cart
      window.localStorage.setItem('rewhiteCart', '');
    },

    addItem(state, payload) {
      const itemIndex = state.cart.items.findIndex((x) => x.id === payload.id);
      if (itemIndex !== -1) {
        state.cart.items[itemIndex].qty++;
        state.cart.items[itemIndex].total = state.cart.items[itemIndex].price * state.cart.items[itemIndex].qty;
        this.commit('saveCart');
      } else {
        state.cart.items.push({
          name: payload.name, id: payload.id, url: payload.url, slug: payload.slug, variantName: payload.variantName, qty: 1, price: payload.price, channel: payload.channel, total: payload.price, meta: payload.meta,
        });
        this.commit('saveCart');
      }
    },

    updateCart(state, newPrices) {
      const that = this;
      console.log('UPDATING CART');
      console.log(newPrices);

      if (newPrices) {
        const refetchedPrices = newPrices.products.edges;
        state.cart.items.forEach((cartItem, cartItemIndex) => {
          let found = false;
          refetchedPrices.forEach((item, itemIndex) => {
            if (!found) {
              const variantIndex = item.node.variants.findIndex((x) => x.id === cartItem.id);
              if (variantIndex !== -1) {
                console.log('FOUND');
                found = true;
                state.cart.items[cartItemIndex].price = refetchedPrices[itemIndex].node.variants[variantIndex].pricing.price.net.amount;
                state.cart.items[cartItemIndex].total = state.cart.items[cartItemIndex].price * state.cart.items[cartItemIndex].qty;
                that.commit('saveCart');
              } else {
                console.log('NOT FOUND');
              }
            }
          });
        });
        if (newPrices.products.pageInfo.hasNextPage) {
          console.log('pagination fetch');
          state.paginationAfter = newPrices.products.pageInfo.endCursor;
        } else {
          console.log('end of list');
        }
      }
    },

    increaseQuantity(state, id) {
      const itemIndex = state.cart.items.findIndex((x) => x.id === id);
      if (itemIndex !== -1) {
        state.cart.items[itemIndex].qty++;
        state.cart.items[itemIndex].total = state.cart.items[itemIndex].price * state.cart.items[itemIndex].qty;
        this.commit('saveCart');
      } else {
        console.log('ERROR : Item not found');
      }
    },

    decreaseQuantity(state, id) {
      const itemIndex = state.cart.items.findIndex((x) => x.id === id);
      if (itemIndex !== -1) {
        if (state.cart.items[itemIndex].qty <= 1) {
          state.cart.items.splice(itemIndex, 1);
          this.commit('saveCart');
        } else {
          state.cart.items[itemIndex].qty--;
          state.cart.items[itemIndex].total = state.cart.items[itemIndex].price * state.cart.items[itemIndex].qty;
          this.commit('saveCart');
        }
      } else {
        console.log('ERROR : Item not found');
      }
    },

    changeQty(state, payload) {
      const itemIndex = state.cart.items.findIndex((x) => x.id === payload.id);
      if (itemIndex !== -1) {
        state.cart.items[itemIndex].qty = payload.qty;
        state.cart.items[itemIndex].total = state.cart.items[itemIndex].price * state.cart.items[itemIndex].qty;
        this.commit('saveCart');
      } else {
        console.log('ERROR : Item not found');
      }
    },

    resetQty(state, id) {
      const itemIndex = state.cart.items.findIndex((x) => x.id === id);
      if (itemIndex !== -1) {
        state.cart.items[itemIndex].qty = 1;
        state.cart.items[itemIndex].total = state.cart.items[itemIndex].price * state.cart.items[itemIndex].qty;
        this.commit('saveCart');
      } else {
        console.log('ERROR : Item not found');
      }
    },

    removeItem(state, id) {
      const itemIndex = state.cart.items.findIndex((x) => x.id === id);
      if (itemIndex !== -1) {
        state.cart.items.splice(itemIndex, 1);
        this.commit('saveCart');
      } else {
        console.log('ERROR : Item not found');
      }
    },

    // ----------------------------------------------//
    // --------------------NAV-----------------------//
    // ----------------------------------------------//

    hideNav(state, value) {
      state.hideNav = value;
    },

    setNavBarBackground(state, value) {
      console.log(`setting to ${value}`);
      state.navBarBackground = value;
    },

    // ----------------------------------------------//
    // --------------------TEAM----------------------//
    // ----------------------------------------------//

    setAthleteSlug(state, slug) {
      state.athleteSlug = slug;
    },

    setTeamFilter(state, filterVal) {
      if (state.currentFilter.includes(filterVal)) {
        const itemIndex = state.currentFilter.findIndex((x) => x === filterVal);
        state.currentFilter.splice(itemIndex, 1);
      } else {
        state.currentFilter.push(filterVal);
      }
    },

    resetTeamFilter(state) {
      state.currentFilter = [];
    },

    addingItem(state, value) {
      state.addingItem = value;
    },

    setSubmitted(state, value) {
      state.submitted = value;
    },

    setNotify(state, value) {
      state.notify = value;
    },

    setSelectedColor(state, color) {
      state.selectedColor = color;
    },

    setContent(state, slug) {
      console.log(`content -> ${slug}`);
      state.contentSlug = slug;
    },

    setPosition(state, position) {
      state.position = position;
    },

    setSelectedSizeIndex(state, index) {
      state.selectedSizeIndex = index;
    },

    setFilteredSelectedSizeIndex(state, index) {
      state.filteredSelectedSizeIndex = index;
    },

    setChannel(state, channel) {
      window.localStorage.setItem('rewhiteChannel', channel);
      state.channel = channel;
      state.paginationAfter = '';
    },

    setNavigatingMobile(state, value) {
      if (value) {
        document.getElementsByTagName('body')[0].style.height = '100%';
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      } else {
        document.getElementsByTagName('body')[0].style.height = 'auto';
        document.getElementsByTagName('body')[0].style.overflow = 'visible';
      }
      state.navigatingMobile = value;
    },

    setCategory(state, slug) {
      state.categorySlug = slug;
    },

    setProduct(state, slug) {
      console.log(`setting slug to -> ${slug}`);
      state.productSlug = slug;
    },

  },
  actions: {
  },
  modules: {
  },
};
