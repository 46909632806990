export const navbar = {
  state: {
    DE:{
      b2b:'Zum Geschäftskundenbereich',
      changeLang: 'Change language',
      about:'Über uns',
      aboutChalk:'Über Chalk',
      sus:'Nachhaltigkeit',
      b2bCustomer:'Geschäftskunden',
      screenPrint:'Textilveredelung',
      contact:'Kontakt',
      agb:'AGB',
      data:'Datenschutz',
      imprint:'Impressum',
      wie:'Widerrufsbelehrung',
      b2bInfo1:'Du befindest dich jetzt im Geschäftskundenbereich und kannst ohne weitere Registrierung für deine Halle / deinen Shop einkaufen. Alle Preise werden zu EK Konditionen angezeigt und deine Artikel im Warenkorb wurden angepasst. Logge dich in deinen Account ein, um alte Bestellungen zu verwalten.',
      back:'Zurück zum Privatkundenbereich',
      back2:'Zum Privatkundenbereich',
      b2bInfo2:'Du befindest dich jetzt im Geschäftskundenbereich und kannst ohne weitere Registrierung für deine Halle / deinen Shop einkaufen.',
      b2bInfo3:'Alle Preise werden zu EK Konditionen angezeigt und deine Artikel im Warenkorb wurden angepasst.',
      b2bInfo4:'Logge dich in deinen Account ein, um alte Bestellungen zu verwalten.',
      more:'Mehr / Bestellungen ansehen',
      loading:'Lädt..',
      addToCart:'In den Warenkorb',
      notify:'Benachrichtigen',
      goBack:'Zurück',
      changeCategory:'Produktkategorie wechseln',
      backToTeam:'Zurück zum Team',
      total:'Gesamt: ',
      toCheckout:'Zum Checkout',
      cartInfo1:'*Alle Preise inkl. 19% MwSt.',
      cartInfo2:'*Alle Preise zzgl. 19% MwSt.',
      cartInfo3:'Mit dieser Bestellung wird ',
      cartInfo4:' Baum in Indonesien gepflanzt',
      cartInfo4:'Mit dieser Bestellung werden ',
      cartInfo6:' Bäume in Indonesien gepflanzt',
      coupon:'Warenkorb anzeigen',
      couponInfo:'Gutscheincodes können hier hinzugefügt werden.',
      couponInfo2:'VAT ID kann hier hinzugefügt werden.',
      vatId:'Warenkorb anzeigen',
      backToCheckout:'Zurück zum Checkout'
    },
    EN:{
      b2b:'Business customer shop',
      changeLang: 'Sprache wechseln',
      about:'About',
      aboutChalk:'About chalk',
      sus:'Sustainability',
      b2bCustomer:'Business customers',
      screenPrint:'Screen printing',
      contact:'Contact',
      agb:'Terms and conditions',
      data:'Data protection',
      imprint:'Imprint',
      wie:'Right of withdrawal',
      b2bInfo1:'You are now in the business customer shop and can purchas without furhter registration for your gym / shop. All prices are now shown at wholesale conditions and the items in your cart got updated. Log into your account to see your order history.',
      back:'Back to end customer shop',
      back2:'To end customer shop',
      b2bInfo2:'You are now in the business customer shop and can purchas without furhter registration for your gym / shop.',
      b2bInfo3:'All prices are now shown at wholesale conditions and the items in your cart got updated.',
      b2bInfo4:'Log into your account to see your order history.',
      more:'More / See order history',
      loading:'Loading..',
      addToCart:'Add to cart',
      notify:'Notify me',
      goBack:'Back',
      changeCategory:'Change product category',
      backToTeam:'Back to team',
      total:'Total: ',
      toCheckout:'Checkout',
      cartInfo1:'*All prices incl. 19% VAT.',
      cartInfo2:'*All prices excl. 19% VAT.',
      cartInfo3:'With this order ',
      cartInfo4:' tree will be planted in Indonesia',
      cartInfo4:'With this order ',
      cartInfo6:' trees will be planted in Indonesia',
      coupon:'Show shopping cart',
      couponInfo:'Coupon codes can be added here.',
      couponInfo2:'VAT ID can be added here.',
      vatId:'Show shopping cart',
      backToCheckout:'Back to checkout'
    }
  }
};
