<script>
export default {
  name:"formatPrice",
  methods:{
    
    formatPrice(price){
      const newPrice = (Math.round(price * 100) / 100).toFixed(2) + " €";
      return newPrice.replace('.',',')
    },

  }
 }
</script>