<template></template>

<script>
import { useMutation, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"recheckItemsQuery",
    
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });

    const recheckItemsQuery = gql`
      query($channel: String!, $ids: [ID!]!) {
        productVariants(
          first: 100, 
          channel: $channel,
          ids: $ids
        ) {
          edges {
            node {
              id
              name
              quantityAvailable
              metadata {
                key
                value
              }
         			pricing{
                price {
                  gross {
                    amount
                  }
                }
              }
            }
          }
        }
      }
      `;
      
    const { execute: recheckItems } = useMutation(recheckItemsQuery);
    
    store.commit('graphqlData', { item: 'recheckItems', result: recheckItems });
  },
}
</script>