<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"refreshTokenMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
     const refreshTokenMutation = gql`
      mutation($CSRF : String!) {
        tokenRefresh(csrfToken: $CSRF) {
          token
          accountErrors {
            code
          }
        }
      }
      `;
  
    const { execute: refreshToken } = useMutation(refreshTokenMutation);
  
    store.commit('graphqlData', { item: 'refreshToken', result: refreshToken });
  },
}
</script>