<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getCategoriesQuery",
  
  watch: {
    categories() {
      this.$store.commit('graphqlData', { item: 'categories', result: this.categories });
     },
  },
    
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL
    });
    
    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang)
    });
    
    const getCategoriesQuery = gql`
      query($lang:Locale!)  {
        categories(locales: [$lang]) {
          id
          name
          slug
        }
      }
    `;
    
    const { data: categories } = useQuery({
      query: getCategoriesQuery,
      variables
    });
    
    return { categories };
  },
}
</script>