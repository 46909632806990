export const countries = {
  state: {
    DE:{
      Deutschland:'Deutschland',
      Österreich:'Österreich',
      Dänemark: 'Dänemark',
      Frankreich:'Frankreich',
      Schweden: 'Schweden',
      Niederlande:'Niederlande',
      Luxemburg:'Luxemburg',
      Belgien: 'Belgien',
      Italien: 'Italien',
      Singapur: 'Singapur',
      Spanien: 'Spanien',
      Tschechien: 'Tschechien',
      Polen: 'Polen',
      Irland:'Irland',
      Schweiz:'Schweiz',
      England:'England',
      Estland:'Estland',
      Finnland:'Finnland',
      Griechenland:'Griechenland',
      Island:'Island',
      Japan:'Japan',
      Litauen:'Litauen',
      Lettland:'Lettland',
      Norwegen:'Norwegen',
      Portugal:'Portugal',
      Russland:'Russland',
      Korea:'Korea',
      Thailand:'Thailand',
      USA:'USA'
    },
    EN:{
      Deutschland:'Germany',
      Österreich:'Austria',
      Dänemark: 'Denmark',
      Frankreich:'France',
      Schweden: 'Sweden',
      Niederlande:'Netherlands',
      Luxemburg:'Luxembourg',
      Belgien: 'Belgium',
      Italien: 'Italy',
      Singapur: 'Singapore',
      Spanien: 'Spain',
      Tschechien: 'Czech Republic',
      Polen: 'Poland',
      Irland:'Ireland',
      Schweiz:'Switzerland',
      England:'England',
      Estland:'Estiona',
      Finnland:'Finnland',
      Griechenland:'Greece',
      Island:'Iceland',
      Japan:'Japan',
      Litauen:'Lithuania',
      Lettland:'Latvia',
      Norwegen:'Norway',
      Portugal:'Portugal',
      Russland:'Russia',
      Korea:'Korea',
      Thailand:'Thailand',
      USA:'USA'
    }
  }
};
