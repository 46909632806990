// eslint-disable-next-line import/prefer-default-export
export const home = {
  state: {
    DE: {
      showAll: 'Alle anzeigen',
      team: 'Unser Team',
      category: 'KATEGORIE',
      news: 'Neuigkeiten',
      more: 'mehr',
    },
    EN: {
      showAll: 'Show all',
      team: 'Our team',
      category: 'CATEGORY',
      news: 'NEWS',
      more: 'more',
    },
  },
};
