<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';


export default {
  name:"refetchPricesQuery",
  
  watch: {
    refetchedPrices() {
      this.$store.commit('updateCart', this.refetchedPrices );
     },
  },
    
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const variables = reactive({
      channel: computed(() => store.state.data.channel),
      after: computed(() => store.state.data.paginationAfter)
    });

    const refetchPricesQuery = gql`
      query ($after: String!, $channel: String!){
        products(first: 25, after: $after, channel: $channel) {
          edges {
            node {
              slug
              name
              id
              variants {
                id
                name
                pricing{
                  price {
                    net {
                      amount
                    }
                    gross {
                      amount
                    }
                    tax {
                      amount
                    }
                }
              }
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
      }
    `;
    
    const { data: refetchedPrices } = useQuery({
      query: refetchPricesQuery,
      fetchOnMount: false,
      variables
    });
    
    return { refetchedPrices };
  },
}
</script>