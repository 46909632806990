<template>
  <Auth />
  <CartLogic />
  <Navbar />
  <router-view v-slot="{ Component }">
      <transition @enter="fadeIn" @leave="fadeOut" :css="false">
          <component :is="Component"/>
      </transition>
  </router-view>
</template>


<script>
import Navbar from './components/navbar.vue';
import Auth from './components/auth.vue';
import CartLogic from './components/cartLogic.vue';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'Master',
  components: { Navbar, Auth, CartLogic },
  
  computed: {
    channel(){
      return this.$store.state.data.channel;
    }
  },
  
  mounted(){
    //User language detection
    if(!window.localStorage.rewhiteLang){
      var userLang = navigator.language || navigator.userLanguage;
      if(userLang == 'de-DE' || userLang == 'de-de'){
        this.$store.commit('setLang', 'DE');
      } else {
        this.$store.commit('setLang', 'EN');
      }
    } else {
      this.$store.commit('setLang', window.localStorage.rewhiteLang);
    }
    
    //User channel detection
    if(!window.localStorage.rewhiteChannel){
      window.localStorage.setItem('rewhiteChannel', this.channel);
    } else {
      this.$store.commit('setChannel', window.localStorage.rewhiteChannel);
    }
  },

  
  methods:{
      
      fadeIn(el, done){
        var aim = el.querySelector("#anim")
        if(aim == null){
          aim = el;
        }
        anime({
          targets: aim,
          easing: 'easeOutSine',
          delay: 250,
          duration: 250,
          translateY: [15, 0],
          opacity: [0,1],
          complete: done
      });
    },
    
    fadeOut(el, done){
        
      anime({
        targets: el,
        easing: 'easeOutSine',
        duration: 250,
        opacity: [1,0],
        complete: done
      });
    },
  }

}

</script>

