export const team = {
  state: {
    DE:{
      routesetter:'Routesetter*innen', 
      athletes: 'Athlet*innen',
      photographers: 'Fotograf*innen'
    },
    EN:{
      routesetter:'Routesetters', 
      athletes: 'Athletes',
      photographers: 'Photographers'
    }
  }
};
