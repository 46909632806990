export const categories = {
  state: {
    DE:{
  
    },
    EN:{

    }
  }
};
