export const notFound = {
  state: {
    DE:{
      notFound:'Ups, sieht so aus als würde es die Seite nicht mehr geben.'
    },
    EN:{
        notFound:'Oops, looks like the page you are looking for does not exist anymore.'
    }
  }
};
