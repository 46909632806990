export const footer = {
  state: {
    DE:{
      newsletter:'Trag dich für unseren Newsletter ein, kein Spam - versprochen!',
      success:'Danke für deine Anmeldung!',
      send:'Abschicken',
      abg:'AGB',
      datenschutz:'Datenschutz',
      impressum:'Impressum',
      kontakt:'Kontakt',
      widerrufsbelehrung:'Widerrufsbelehrung'
    },
    EN:{
      newsletter:'Sign up for our newsletter, no spam - promise!',
      success:'Thank you for your subscription!',
      send:'Send',
      abg:'Terms and conditions',
      datenschutz:'Data protection',
      impressum:'Imprint',
      kontakt:'Contact',
      widerrufsbelehrung:'Right of withdrawal'
    }
  }
};
