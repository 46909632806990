<template>
  <refreshTokenMutation />
  <logoutMutation />
</template>

<script>
import refreshTokenMutation from '../graphql/saleor/refreshTokenMutation.vue';
import logoutMutation from '../graphql/saleor/logoutMutation.vue';

export default {
  name: 'Auth',
  components: { refreshTokenMutation, logoutMutation },
  computed: {
    // graphql
    refreshToken() {
      return this.$store.state.data.refreshToken;
    },
    logoutUser() {
      return this.$store.state.data.logoutUser;
    },
    // data
    auth() {
      return this.$store.state.data.auth;
    },
    channel() {
      return this.$store.state.data.channel;
    },
    logout() {
      return this.$store.state.data.logout;
    },
  },

  watch: {
    channel() {
      if (this.channel === 'b2c') {
        if (this.auth) {
          this.logoutUser();
        }
      }
    },
    logout() {
      console.log(this.logout);
      const errors = this.logout.tokensDeactivateAll.accountErrors;
      if (errors.length === 0) {
        console.log('User is logged out');
        this.$store.commit('setUserAuthStatus', false);
        this.$store.commit('setAccessToken', null);
      } else {
        console.log('Error logging out');
        console.log(errors);
      }
    },
  },

  mounted() {
    window.addEventListener('storage', this.syncLogout);

    // User login status detection
    if (typeof (Storage) !== 'undefined') {
      if (window.localStorage.rewhiteSession) {
        console.log('CSRF exists');
        this.refreshToken({
          CSRF: window.localStorage.rewhiteSession,
        }).then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            const errors = result.data.tokenRefresh.accountErrors;
            if (errors.length === 0) {
              console.log('User is logged in');
              this.$store.commit('setUserAuthStatus', true);
              this.$store.commit('setAccessToken', result.data.tokenRefresh.token);
              this.$store.commit('setChannel', 'b2b');
            } else {
              console.log(errors);
              console.log('User is logged out');
              this.$store.commit('setUserAuthStatus', false);
              this.$store.commit('setAccessToken', null);
            }
          }
        });
      } else {
        console.log('No CSRF');
        this.$store.commit('setUserAuthStatus', false);
      }
    } else {
      console.log('No Web Storage support');
    }
  },

  methods: {
    syncLogout(event) {
      if (event.key === 'rewhiteSession' && event.newValue == null) {
        this.$store.commit('setUserAuthStatus', false);
        this.$store.commit('setAccessToken', null);
      }
    },
  },

};
</script>
